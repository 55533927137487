import { createRouter, createMemoryHistory } from "vue-router";
import Tools from "../views/Tools.vue";
import UnitConvert from "../views/UnitConvert.vue";
import FillMagic from "../views/FillMagic.vue";

const routes = [
  {
    path: "/",
    name: "Home Tools",
    component: Tools,
    alias: ["/taskpane", "/taskpane.html"],
  },
  {
    path: "/UnitConvert",
    name: "UnitConvert",
    component: UnitConvert,
  },
  {
    path: "/FillMagic",
    name: "FillMagic",
    component: FillMagic,
  },
];
const router = createRouter({
  history: createMemoryHistory(),
  routes,
});

export default router;
