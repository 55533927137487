<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="main m-1 text-center">
    <div class="header">
      <p><Button label="Text Case Tools" @click="textCaseChange()" /></p>
    </div>
    <div class="grid m-1" v-if="textCase">
      <div class="col-6 align-items-center">
        <Button
          label="Uppercase"
          @click="uppercase()"
          severity="info"
          style="min-width: 100%; min-height: 100%"
        />
      </div>
      <div class="col-6 align-items-center">
        <Button
          label="Proper Case"
          @click="properCase()"
          severity="info"
          style="min-width: 100%; min-height: 100%"
        />
      </div>
      <div class="col-6 align-items-center">
        <Button
          label="Sentence Case"
          @click="sentenceCase()"
          severity="info"
          style="min-width: 100%; min-height: 100%"
        />
      </div>
      <div class="col-6 align-items-center">
        <Button
          label="Test Function"
          @click="testFunc()"
          severity="info"
          style="min-width: 100%; min-height: 100%"
        />
      </div>
    </div>
    <div class="header">
      <p>
        <Button label="Cell Format Tools" @click="cellFormatChange()" />
      </p>
    </div>
    <div class="grid m-1" v-if="cellFormat">
      <div class="col-6 align-items-center">
        <Button
          label="Alternate Row Colors"
          @click="alternateColors()"
          severity="info"
          style="min-width: 100%; min-height: 100%"
        />
      </div>
      <div class="col-6 align-items-center">
        <Button
          label="Quick Border"
          @click="border()"
          severity="info"
          style="min-width: 100%; min-height: 100%"
        />
      </div>
    </div>
    <div class="header">
      <p>
        <Button label="Range Editing Tools" @click="rangeEditChange()" />
      </p>
    </div>
    <div class="grid m-1" v-if="rangeEdit">
      <div class="col-6 align-items-center">
        <Button
          label="Paste Values"
          @click="pasteValues()"
          severity="info"
          style="min-width: 100%; min-height: 100%"
        />
      </div>
      <div class="col-6 align-items-center">
        <Button
          label="Exact Copy"
          @click="exactCopy()"
          severity="info"
          style="min-width: 100%; min-height: 100%"
        />
      </div>
      <div class="col-6 align-items-center">
        <router-link to="/FillMagic">
          <Button
            label="Fill Magic"
            severity="info"
            style="min-width: 100%; min-height: 100%"
          />
        </router-link>
      </div>
      <div class="col-6 align-items-center">
        <Button
          label="TBD"
          @click="null"
          severity="info"
          style="min-width: 100%; min-height: 100%"
        />
      </div>
    </div>
    <div class="main">
      <p>
        <Button label="Custom Functions" @click="customFuncChange()" />
      </p>
    </div>
    <div class="grid" v-if="customFunc">
      <div class="col-6 align-items-center">
        <router-link to="/UnitConvert">
          <Button
            label="Unit Conversions"
            severity="info"
            style="min-width: 100%; min-height: 100%"
          />
        </router-link>
      </div>
      <div class="col-6 align-items-center">
        <Button
          label="Velocity"
          severity="info"
          style="min-width: 100%; min-height: 100%"
          @click="quickLambda('velocity')"
        />
      </div>
      <div class="col-6 align-items-center">
        <Button
          label="Diameter from Area"
          severity="info"
          style="min-width: 100%; min-height: 100%"
          @click="quickLambda('dia')"
        />
      </div>
      <div class="col-6 align-items-center">
        <Button
          label="Sludge Density"
          severity="info"
          style="min-width: 100%; min-height: 100%"
          @click="quickLambda('sludge')"
        />
      </div>
    </div>
  </div>
  <ColorDialog />
  <LambdaConfirmed />
  <ErrorDialog />
</template>

<script setup>
import ColorDialog from "../components/ColorDialog.vue";
import LambdaConfirmed from "../components/LambdaConfirmed.vue";
import ErrorDialog from "../components/ErrorDialog.vue";
import {
  uppercase,
  sentenceCase,
  properCase,
  testFunc,
} from "../methods/changeCase.js";
import { border } from "../methods/border.js";
import { lambdaFunc } from "../methods/lambda.js";
import { colorDialog, lambdaConfirm, errorMessage } from "../methods/store.js";

import { ref } from "vue";

var textCase = ref(false);
var cellFormat = ref(false);
var rangeEdit = ref(false);
var customFunc = ref(false);

function textCaseChange() {
  textCase.value == true ? (textCase.value = false) : (textCase.value = true);
}

function cellFormatChange() {
  cellFormat.value == true
    ? (cellFormat.value = false)
    : (cellFormat.value = true);
}

function rangeEditChange() {
  rangeEdit.value == true
    ? (rangeEdit.value = false)
    : (rangeEdit.value = true);
}

function customFuncChange() {
  customFunc.value == true
    ? (customFunc.value = false)
    : (customFunc.value = true);
}

function alternateColors() {
  colorDialog.show = true;
}

async function quickLambda(type) {
  var result = { msg: "Error. Something Bad Happened" };
  if (type == "dia") {
    result = await lambdaFunc(
      type,
      `=LAMBDA(area,(SQRT((area*4)/PI())))`,
      "Enter area in sqft. Get approximate diameter in inches"
    );
  }
  if (type == "sludge") {
    result = await lambdaFunc(
      "sludge_density",
      `=LAMBDA(percent_solids,(6.465*percent_solids^3-0.534*percent_solids^2+5.371*percent_solids+8.337))`,
      "Enter percent solids, get approximate sludge density"
    );
  }
  if (type == "velocity") {
    result = await lambdaFunc(
      "velocity",
      `=LAMBDA(size_in,flow_gpm,(flow_gpm*0.002228)/(3.14159*(size_in/12)^2/4))`,
      "Enter size in inches and flow in GPM. Get velocity in feet per second"
    );
  }
  lambdaConfirm.result = result.msg;
  lambdaConfirm.show = true;
}

function exactCopy() {
  errorMessage.header = "Sorry!";
  errorMessage.message = "This feature is not quite ready.";
  errorMessage.show = true;
}

function pasteValues() {
  errorMessage.header = "Sorry!";
  errorMessage.message = "This feature is not quite ready.";
  errorMessage.show = true;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
